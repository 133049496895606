<template>
  <div class="mt-4">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Nama Marketing</label>
        <vs-input class="w-full" :value="initData.staf.nama" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Jabatan</label>
        <vs-input class="w-full" :value="initData.staf.nama_jabatan" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full -mt-1">
        <label class="ml-1 text-xs opacity-75">Telp</label>
        <vs-input class="w-full" type="text"  :value="initData.staf.telp" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full -mt-1">
        <label class="ml-1 text-xs opacity-75">Email</label>
        <vs-input class="w-full" type="text" :value="initData.staf.email" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-3/12 w-full -mt-1">
        <label class="ml-1 text-xs opacity-75">No. Rekening</label>
        <vs-input class="w-full" type="text" :value="initData.staf.no_rekening || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">Bank Rekening</label>
        <vs-input class="w-full" :value="initData.staf.bank_rekening || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">A.N Rekening</label>
        <vs-input class="w-full" :value="initData.staf.an_rekening || '-'" readonly/>
      </div>
      <div class="vx-col sm:w-3/12 w-full">
        <label class="ml-1 text-xs">NPWP</label>
        <vs-input class="w-full" :value="initData.staf.no_npwp || '-'" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/1 w-full">
        <label class="ml-1 text-xs">Alamat</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" :value="initData.staf.alamat" readonly/>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full">
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TabStaf',
  props: {
    initData: { default: null, type: Object }
  },
  methods: {
    next () {
      const currentActiveTab = this.$store.state.approvals.komisiApproval.activeTab
      this.$store.commit('approvals/komisiApproval/SET_ACTIVE_TAB', currentActiveTab + 1)
    }
  }
}
</script>
